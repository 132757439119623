import {Adsense} from '@ctrl/react-adsense';
import React, {useState} from 'react';
import {GameInterface} from "../../../interfaces";
import "./ErrorGame.scss";
import {Alert, Button} from "react-bootstrap";

type Props = {
    game: GameInterface
}

export const ErrorGame = (props: Props) => {
    enum State {
        NotLoaded,
        Loading,
        Loaded,
    }

    const [state, setState] = useState<State>(State.NotLoaded as State);
    const game = props.game;
    const background = game.image ?? game.assets[0].url;

    const loadGame = () => {
        if (state === State.NotLoaded) {
            setState(State.Loading as State);
            setTimeout(() => {
                setState(State.Loaded as State);
            }, Math.floor(Math.random() * 1000) + 1000)
        }
    };

    function GameErrorAlert() {
        const [show, setShow] = useState(true);

        if (show) {
            return (
                <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                    Niestety nie możemy załadować gry.
                </Alert>
            );
        }
        return null;
    }

    return (
        <div className="error-game">
            {state != State.Loaded ? (
                <div className={`not-loaded-box text-center ${state == State.NotLoaded ? 'not-loaded' : 'loading'}`}
                     style={{
                         backgroundImage: `url(${background})`
                     }}>
                    <div className="icon">
                        {state == State.NotLoaded && (
                            <i className="bi bi-play-circle" onClick={loadGame}/>
                        )}
                        {state == State.Loading && (
                            <i className="bi bi-arrow-clockwise rotating-icon"/>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <GameErrorAlert/>
                    <Adsense
                        client="ca-pub-1582647289978639"
                        slot="9839754087"
                        style={{display: 'block'}}
                        responsive="true"
                        format="fluid"
                    />
                </>
            )}
        </div>
    )
}