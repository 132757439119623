import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {PageLayout} from "../Layout/PageLayout";
import {TagInterface, GamesBlockInterface} from "../../interfaces";
import {Outlet} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import {CategoryTile} from "../Blocks/CategoryTile/CategoryTile";
import Header from "../Text/Header";
import {GamesBlock} from "../Blocks/GamesBlock/GamesBlock";

type Props = {}

export const TagsPage = (props: Props) => {
    const [tags, setTags] = useState<TagInterface[]>([]);
    const [gamesBlocks, setGamesBlocks] = useState<GamesBlockInterface[]>([]);

    useEffect(() => {
        axios
            .get('/tag/')
            .then((resp: AxiosResponse) => {
                setTags(resp.data)
            })

    }, []);

    useEffect(() => {
        axios
            .get('/games_block/', {
                params: {
                    place: "tag"
                }
            })
            .then((resp: AxiosResponse) => {
                setGamesBlocks(resp.data)
            })
    }, []);

    return (
        <PageLayout>
            <Container>
                {/*<Row>*/}
                {/*    <Col>*/}
                {/*        <Header textFirst="Tagi gier"/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {tags.length && (
                    <Row className="mb-5">
                        {tags.map((tag) => {
                            return (
                                <Col className="px-2" xs="6" lg="3">
                                    <CategoryTile large category={tag} tag/>
                                </Col>
                            );
                        })}
                    </Row>
                )}
            </Container>
            <Container>
                <Row>
                    <Col>
                        {gamesBlocks.map((gameBlock) => {
                            return <GamesBlock gamesBlockData={gameBlock}/>
                        })}
                    </Col>
                </Row>
            </Container>
        </PageLayout>
    );
}