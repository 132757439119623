import React from 'react';
import {Button} from "react-bootstrap";
import './ActionButton.scss';

type Props = {
    children: React.ReactNode;
    action: () => void;
    icon: string;
    background?: string;
    color?: string;
    iconAsImage?: boolean;
};

export const ActionButton = (props: Props) => {
    const background = props.background ?? "#FFFFFF";
    const color = props.color ?? "#FD7A48";

    return (
        <Button variant="light" className="button-action" style={{
            backgroundColor: background,
            color: color
        }} onClick={props.action}>
            {props.iconAsImage ?
                <img src={props.icon}/> :
                <i className={`bi bi-${props.icon}`}/>
            }
            {props.children}
        </Button>
    );
}