import { useEffect, useState } from 'react';
import { Button, Modal, Nav } from "react-bootstrap";
import axios, { AxiosResponse } from "axios";
import { ButtonRounded } from "../../Button/ButtonRounded/ButtonRounded";
import { Link } from "react-router-dom";

type Props = {
    icon: string;
    color?: string;
};

export const MobileMenu = (props: Props) => {
    const [openMenu, setOpenMenu] = useState(false);
    const handleCloseMenu = () => setOpenMenu(false);
    const handleShowMenu = () => setOpenMenu(true);

    const [menuLinks, setMenuLinks] = useState<any[]>([]);

    useEffect(() => {
        axios
            .get('/menu_link/', {
                params: {
                    menu_type: 'main'
                }
            })
            .then((resp: AxiosResponse) => {
                setMenuLinks(resp.data)
            })
    }, []);


    return (
        <>
            <Button className="button-menu_switcher d-flex d-lg-none" onClick={handleShowMenu}>
                <i className={`bi bi-${props.icon}`} />
            </Button>
            <Modal className="mobile-menu-modal" show={openMenu} backdrop={false} onHide={handleCloseMenu}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="modal-content mobile-menu-modal-body">
                    {menuLinks.length && menuLinks.slice(1, 6).map((menuLink) => {
                        return (
                            <Link to={menuLink.url}>
                                <ButtonRounded key={menuLink.id} title={menuLink.name} icon={menuLink.icon}
                                    background={menuLink.color} />
                            </Link>
                        );
                    })}

                </Modal.Body>
            </Modal>
        </>
    )
}