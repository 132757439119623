import React, {useEffect, useState} from 'react';
import Header from "../../Text/Header";
import {GameInterface, GamesBlockInterface} from "../../../interfaces";
import axios, {AxiosResponse} from "axios";
import {GameTile} from "../GameTile/GameTile";
import {CategoryTile} from "../CategoryTile/CategoryTile";
import { Link } from 'react-router-dom';
import './GamesBlock.scss';

type Props = {
    gamesBlockData: GamesBlockInterface
};

export const GamesBlock = (props: Props) => {
    const [games, setGames] = useState<GameInterface[]>([]);
    const gamesBlockData = props.gamesBlockData;
    let title = gamesBlockData.title ?? gamesBlockData.tag.name ?? "";
    let url:string = gamesBlockData.url!;
    let [first, ...others] = title.split(' ');
    let second = others.join(' ');

    useEffect(() => {
        if (gamesBlockData.tag) {
            axios
                .get('/game/', {
                    params: {
                        tags: gamesBlockData.tag.id,
                        limit: gamesBlockData.games_count,
                        rand: true
                    }
                })
                .then((resp: AxiosResponse) => {
                    setGames(resp.data.results)
                })
        }
    }, [gamesBlockData]);

    return (
        <div className="games-block">
            <Header textFirst={first} textSecond={second}/>
            {games.length && (
                <div className="row">
                    {games.map((game) => {
                        return (
                            <div className="col-4 col-lg-2 px-2">
                                <GameTile game={game}/>
                            </div>
                        );
                    })}
                    <div className="moreGames-wrap col-4 col-lg-2 px-2">
                        <a className="moreGames-link" href={url}>
                        <div className="moreGames">
                            <h3 className="moreGames-title">{title}</h3>
                            <div className="moreGames-link">więcej <i className="bi bi-chevron-right"></i></div>
                        </div>
                        </a>
                    </div>
                </div>
            )}
            {gamesBlockData.categories && (
                <div className="row">
                    {gamesBlockData.categories.map((category) => {
                        return (
                            <div className="col-6 col-lg-4 px-2">
                                <CategoryTile category={category}/>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}