import Reach, { useEffect, useState } from "react";
import { GameInterface, GamesBlockInterface } from "../../../interfaces";
import axios, { AxiosResponse } from "axios";
import { GameTile } from "../GameTile/GameTile";
import { Link } from "react-router-dom";
import { ButtonImage } from "../../Button/ButtonImage/ButtonImage";
import searchImage from "../../../assets/search.svg";
import './SearchBar.scss';
import { Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { ActionButton } from "../../Button/Action/ActionButton";
import React from "react";

export const SearchBar = () => {
    const [games, setGames] = useState<GameInterface[]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchResultsVisibility, setSearchResultsVisibility] = useState('hidden');

    useEffect(() => {
        axios
            .get('/game/', {
                params: {
                    search: searchText,
                    limit: 12
                }
            })
            .then((resp: AxiosResponse) => {
                setGames(resp.data.results)
            })
    }, [searchText]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        if (e.target.value == '') {
            setSearchResultsVisibility('hidden');
        } else {
            setSearchResultsVisibility('visible');
        }
    }
    let navigate = useNavigate();
    const submitSearch = () => {
        if (searchText != '') {
            navigate(`/szukaj/${searchText}`);
            setSearchText('');
            setSearchResultsVisibility('hidden');
        } else {
            return;
        }

    }
    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter'){
            navigate(`/szukaj/${searchText}`);
            setSearchText('');
            setSearchResultsVisibility('hidden');
        };
    }

    return (
        <div className="d-flex search-form">
            <input
                type="search"
                aria-label="Search"
                placeholder="Szukaj gry..."
                className="form-field--input"
                value={searchText}
                onChange={handleChange}
                onKeyUp={handleKeyUp}/>
            <ButtonImage
                action={submitSearch}
                image={searchImage}
                backgroundColorFrom="#25D6FF"
                backgroundColorTo="#2A7BFE"
            />
            {games.length && (
                <div className="search-results" data-visibility={searchResultsVisibility}>
                    <Row className="search-games-wrap">
                        {games.map((game) => {
                            return (
                                <div className="col-6 col-md-4 col-lg-3 search-gameTile">
                                    <Link to={`/gra/${game.slug}`}>
                                        <img width="100%" className="search-gameImg" src={game.image} />
                                        <span className="search-gameName">{game.name}</span>
                                    </Link>
                                </div>
                            );
                        })}
                    </Row>
                    {games.length > 3 && (
                        <Row className="more-games-wrap">
                            <button className="button-action btn btn-light" onClick={submitSearch}>Więcej wyników</button>
                        </Row>
                    )}
                </div>
            )}
        </div>
    )
}



