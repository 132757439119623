import React from "react";
import {FooterNavbar} from "./FooterNavbar/FooterNavbar";
import {FooterSignature} from "./FooterSignature/FooterSignature";
import {Container} from "react-bootstrap";
import footerBackground from "../../../assets/footer-bg-main.png";
import {FooterTopBackground} from "./FooterTopBackground/FooterTopBackground";
import { MobileFooterMenu } from "./MobileFooterMenu/MobileFooterMenu";

export const PageFooter = () => {
    return (
        <>
            <FooterTopBackground background={footerBackground} class="footer-img"/>
            <Container fluid className="main-footer" style={{backgroundColor: "#4090FF"}}>
                <FooterNavbar/>
                <FooterSignature/>
                <MobileFooterMenu/>
            </Container>
        </>
    );
}