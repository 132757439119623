import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import axios, { AxiosResponse } from "axios";
import "./FooterSignature.scss";

export const FooterSignature = () => {

    return (
        <div className="footer-signature">
            <Navbar>
                <Container className="d-block">
                    <Nav className="d-none d-lg-flex footer-signature-desktop">
                        <Nav.Link className="d-none d-lg-block" href="/">MojeGry.pl &copy; 2022</Nav.Link>
                        <Nav.Link href="/">Zgoda na pliki Cookies</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </div>
    );
}
