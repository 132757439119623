import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {PageLayout} from "../Layout/PageLayout";
import {GameInterface, TagInterface} from "../../interfaces";
import {useNavigate, useParams} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import Header from "../Text/Header";
import {GameTile} from "../Blocks/GameTile/GameTile";
import {ActionButton} from "../Button/Action/ActionButton";
import {MetaTags} from "../Seo/MetaTags/MetaTags";

type Props = {}

export const TagPage = (props: Props) => {
    const [tag, setTag] = useState<TagInterface>();
    const [games, setGames] = useState<GameInterface[]>([]);
    const [first, setFirst] = useState<string>('');
    const [second, setSecond] = useState<string>('');
    const [nextPage, setNextPage] = useState<string | null>(null);

    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        if (params.slug) {
            axios
                .get(`/tag/${params.slug}`)
                .then((resp: AxiosResponse) => {
                    setTag(resp.data);
                })
        }
    }, [params]);

    useEffect(() => {
        if (tag?.id) {
            axios
                .get('/game/', {
                    params: {
                        tags: tag.id,
                        limit: 72,
                        rand: true
                    }
                })
                .then((resp: AxiosResponse) => {
                    setGames(resp.data.results);
                    setNextPage(resp.data.next);
                });

            let [first, ...others] = tag.name.split(' ');
            setFirst(first);
            setSecond(others.join(' '));
        }
    }, [tag]);

    const loadNextGames = () => {
        if (nextPage) {
            axios
                .get(nextPage)
                .then((resp: AxiosResponse) => {
                    setGames([...games, ...resp.data.results]);
                    setNextPage(resp.data.next);
                })
        }
    }

    return (
        <>
            <MetaTags title={tag?.meta_title} description={tag?.meta_description}/>
            <PageLayout>
                <Container>
                    {(first || second) && (
                        <Row>
                            <Col>
                                <Header textFirst={first} textSecond={second}/>
                            </Col>
                        </Row>
                    )}
                    {games.length ?
                        <Row>
                            {games.map((game) => {
                                return (
                                    <Col className="px-2" xs="4" lg="2" key={game.id}>
                                        <GameTile game={game}/>
                                    </Col>
                                );
                            })}
                        </Row>
                        : <p>Brak gier w tej kategorii.</p>
                    }
                    {nextPage && (
                        <Row className="mb-5 mb-lg-0">
                            <Col className="text-center">
                                <ActionButton icon="chevron-right" action={loadNextGames}>Więcej gier</ActionButton>
                            </Col>
                        </Row>
                    )}
                </Container>
            </PageLayout>
        </>
    );
}