import React from "react";
import {Container} from "react-bootstrap";
import "./FooterTopBackground.scss";

type Props = {
    background: string,
    class: string
}

export const FooterTopBackground = (props: Props) => {
    return (
        <Container fluid style={{
            backgroundImage: `url(${props.background})`,
            backgroundSize: "cover"
        }} className= {props.class} />
    );
}