import React from 'react';
import {Button} from "react-bootstrap";
import './ButtonImage.scss';

type Props = {
    image: string,
    backgroundColor?: string
    backgroundColorFrom?: string
    backgroundColorTo?: string
    action?: () => void;
};

export const ButtonImage = (props: Props) => (
    <Button variant="light" className="button-image"
            style={{
                background: `linear-gradient(${props.backgroundColorFrom}, ${props.backgroundColorTo})`
            }} onClick={props.action}>
        <img
            src={props.image}
        />
    </Button>
);