import React, {useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import './App.scss';
import MainPage from "./components/Page/MainPage";
import {CategoryPage} from "./components/Page/CategoryPage";
import {CategoriesPage} from "./components/Page/CategoriesPage";
import {GamePage} from "./components/Page/GamePage";
import {SearchPage} from './components/Page/SearchPage';
import {Page404} from './components/Page/Page404';
import {StaticPage} from './components/Page/StaticPage';
import WindowFocusHandler from './components/Handlers/WindowFocusHandler';
import {TagPage} from "./components/Page/TagPage";
import {TagsPage} from "./components/Page/TagsPage";
import {setupResponseInterceptor} from './components/HttpClient/HttpClient';
import {Maintenance} from "./components/Page/Maintenance";

function App() {
    const navigate = useNavigate()
    const [isLoaded, setIsLoaded] = useState(false)

    const maintenance = process.env.REACT_APP_MAINTENANCE === "true";

    if (!isLoaded) {
        setIsLoaded(true)
        setupResponseInterceptor(navigate)
    }

    return (
        <>
            {maintenance ? (
                <>
                    <Maintenance/>
                </>
            ) : (
                <>
                    <WindowFocusHandler/>

                    <Routes>
                        <Route path="/" element={<MainPage/>}/>
                        <Route path="/404" element={<Page404/>}/>
                        <Route path="kategorie" element={<TagsPage/>}/>
                        <Route path="kategoria/:slug" element={<CategoryPage/>}/>
                        <Route path="tagi" element={<TagsPage/>}/>
                        <Route path="tag/:slug" element={<TagPage/>}/>
                        <Route path="szukaj/:query" element={<SearchPage/>}/>
                        <Route path="gra/:slug" element={<GamePage/>}/>
                        <Route path="/:slug" element={<StaticPage/>}/>
                        <Route path="*" element={<Page404/>}/>

                    </Routes>
                </>
            )}
        </>

    );

}

export default App;
