import React from 'react';
import {CategoryInterface, TagInterface} from "../../../interfaces";
import './CategoryTile.scss';
import {Link} from "react-router-dom";

type Props = {
    category: CategoryInterface|TagInterface,
    large?: boolean,
    tag?: boolean
}

export const CategoryTile = (props: Props) => {
    const category = props.category;

    const url_base = props.tag ? 'tag' : 'kategoria';

    return (
        <Link to={`/${url_base}/${category.slug}`}>
            <div className={`category-tile ${props.large ? 'large' : ''}`}>
                <img width="36" height="36" src={category.image}/>
                <span style={props.large ? {backgroundColor: category.color} : {}}>{category.name}</span>
            </div>
        </Link>
    );
}