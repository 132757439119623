import React from 'react';
import './Header.scss';

type Props = {
    textFirst?: string;
    textSecond?: string;
    version?: "color" | "white";
    headerLocation?: "gamePage" | "modal" | "other";
};

function Header(props: Props) {
    let version = props.version ?? "color";
    let headerLocation = props.headerLocation ?? "other";

    return (
        <h1 className={`text-header ${version} header-${headerLocation}`}>{props.textFirst && (
            <span>{props.textFirst} </span>)}{props.textSecond && (props.textSecond)}</h1>
    );
}

export default Header;