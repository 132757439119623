import React, {useEffect, useState} from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";
import axios, {AxiosResponse} from "axios";
import {ButtonRounded} from "../../Button/ButtonRounded/ButtonRounded";
import {Link} from "react-router-dom";
import MainPage from '../../Page/MainPage';

export const TopNavbar = () => {
    const [menuLinks, setMenuLinks] = useState<any[]>([]);

    useEffect(() => {
        axios
            .get('/menu_link/', {
                params: {
                    menu_type: 'main'
                }
            })
            .then((resp: AxiosResponse) => {
                setMenuLinks(resp.data)
            })
    }, []);

    return (
        <Navbar className="d-none d-lg-flex" expand="lg">
            <Container>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-0 w-100 justify-content-between">
                        <Link className="topNavbar--home" to="/kategorie"><i className="bi bi-list"></i></Link>
                        {menuLinks.map((menuLink) => {
                            return (
                                <Link className="topNavbar--link" to={menuLink.url}>
                                    <ButtonRounded key={menuLink.id} title={menuLink.name} icon={menuLink.icon}
                                                   background={menuLink.color}/>
                                </Link>
                            );
                        })}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};