import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {PageLayout} from "../Layout/PageLayout";
import {CategoryInterface, GameInterface} from "../../interfaces";
import {useNavigate, useParams} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import Header from "../Text/Header";
import {GameTile} from "../Blocks/GameTile/GameTile";
import {ActionButton} from "../Button/Action/ActionButton";
import {CategoryTile} from "../Blocks/CategoryTile/CategoryTile";

type Props = {}

export const SearchPage = (props: Props) => {
    const [games, setGames] = useState<GameInterface[]>([]);
    const [nextPage, setNextPage] = useState<string | null>(null);

    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        if (params.query) {
            axios
            .get('/game/', {
                params: {
                    search: params.query,
                    limit: 12
                }
            })
            .then((resp: AxiosResponse) => {
                setGames(resp.data.results);
            });
        }
    }, [params]);


    const loadNextGames = () => {
        if (nextPage) {
            axios
                .get(nextPage)
                .then((resp: AxiosResponse) => {
                    setGames([...games, ...resp.data.results]);
                    setNextPage(resp.data.next);
                })
        }
    }

    return (
        <>
            <PageLayout>
                <Container>
                        <Row>
                            <Col>
                                <Header textFirst="Wyszukiwanie gier:" textSecond={params.query}/>
                            </Col>
                        </Row>
                    {games.length ?
                        <Row>
                            {games.map((game) => {
                                return (
                                    <Col className="px-2" xs="4" lg="2" key={game.id}>
                                        <GameTile game={game}/>
                                    </Col>
                                );
                            })}
                        </Row>
                    : <p>Brak gier w tej kategorii.</p>
                    }
                    {nextPage && (
                        <Row className="mb-5 mb-lg-0">
                            <Col className="text-center">
                                <ActionButton icon="chevron-right" action={loadNextGames}>Więcej gier</ActionButton>
                            </Col>
                        </Row>
                    )}
                </Container>
            </PageLayout>
        </>
    );
}