
import {Col, Container, Row} from "react-bootstrap";
import {PageLayout} from "../Layout/PageLayout";
import errorImg from "../../assets/404.png";
import { ActionButton } from "../Button/Action/ActionButton";
import { useNavigate } from "react-router-dom";
import {useEffect} from "react";

type Props = {}

export const Page404 = () => {

    let navigate = useNavigate();
    const RedirectToHome = () =>{
        navigate('/');
    }

    // temporary redirect to main page
    useEffect(() => {
        navigate('/');
    }, []);

    return (
        <PageLayout>
            <Container className="py-5">
                <Row>
                    <Col className="py-5 d-flex justify-content-center align-items-center flex-column">
                        <img src={errorImg} alt="Błąd 404" className="mb-5 errorPage--img"/>
                        <h2 className="errorPage--heading text-center mb-4">Strona której szukasz nie została odnaleziona.</h2>
                        <ActionButton action={RedirectToHome} icon="chevron-left">Wróć do strony głównej</ActionButton>
                    </Col>
                </Row>

            </Container>

        </PageLayout>
    );
}