import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import axios, { AxiosResponse } from "axios";
import "./MobileFooterMenu.scss";

export const MobileFooterMenu = () => {
    const [menuLinks, setMenuLinks] = useState<any[]>([]);

    useEffect(() => {
        axios
            .get('/menu_link/', {
                params: {
                    menu_type: 'footer'
                }
            })
            .then((resp: AxiosResponse) => {
                setMenuLinks(resp.data)
            })
    }, []);
    return (
        <div className="mobile-footer">
            <Navbar>
                <Container className="d-block">
                    <Nav className="d-flex d-lg-none footer-menu-mobile justify-content-center">
                        {menuLinks.map((menuLink) => {
                            return (
                                <Nav.Link key={menuLink.id}
                                    href={menuLink.url}>{menuLink.name}</Nav.Link>
                            );
                        })}
                        <Nav.Link href="/">Zgoda na pliki Cookies</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </div>
    );
}
