import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {PageLayout} from "../Layout/PageLayout";
import {CategoryInterface, GameInterface} from "../../interfaces";
import {useNavigate, useParams} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import Header from "../Text/Header";
import {GameTile} from "../Blocks/GameTile/GameTile";
import {ActionButton} from "../Button/Action/ActionButton";
import {CategoryTile} from "../Blocks/CategoryTile/CategoryTile";
import {MetaTags} from "../Seo/MetaTags/MetaTags";

type Props = {}

export const CategoryPage = (props: Props) => {
    const [category, setCategory] = useState<CategoryInterface>();
    const [categories, setCategories] = useState<CategoryInterface[]>([]);
    const [games, setGames] = useState<GameInterface[]>([]);
    const [first, setFirst] = useState<string>('');
    const [second, setSecond] = useState<string>('');
    const [nextPage, setNextPage] = useState<string | null>(null);

    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        if (params.slug) {
            axios
                .get('/category/', {
                    params: {
                        slug: params.slug
                    }
                })
                .then((resp: AxiosResponse) => {
                    if (resp.data.length > 0) {
                        setCategory(resp.data[0]);
                    } else {
                        navigate("/404")
                    }
                })
            axios
                .get('/category/', {
                    params: {
                        limit: 8
                    }
                })
                .then((resp: AxiosResponse) => {
                        setCategories(resp.data.results);
                })
        }
    }, [params]);

    useEffect(() => {
        if (category?.id) {
            axios
                .get('/game/', {
                    params: {
                        categories: category.id,
                        limit: 24
                    }
                })
                .then((resp: AxiosResponse) => {
                    setGames(resp.data.results);
                    setNextPage(resp.data.next);
                });

            let [first, ...others] = category.name.split(' ');
            setFirst(first);
            setSecond(others.join(' '));
        }
    }, [category]);

    const loadNextGames = () => {
        if (nextPage) {
            axios
                .get(nextPage)
                .then((resp: AxiosResponse) => {
                    setGames([...games, ...resp.data.results]);
                    setNextPage(resp.data.next);
                })
        }
    }

    return (
        <>
            <MetaTags title={category?.meta_title} description={category?.meta_description}/>
            <PageLayout>
                <Container>
                    {(first || second) && (
                        <Row>
                            <Col>
                                <Header textFirst={first} textSecond={second}/>
                            </Col>
                        </Row>
                    )}
                    {games.length ?
                        <Row>
                            {games.map((game) => {
                                return (
                                    <Col className="px-2" xs="4" lg="2" key={game.id}>
                                        <GameTile game={game}/>
                                    </Col>
                                );
                            })}
                        </Row>
                    : <p>Brak gier w tej kategorii.</p>
                    }
                    {nextPage && (
                        <Row className="mb-5 mb-lg-0">
                            <Col className="text-center">
                                <ActionButton icon="chevron-right" action={loadNextGames}>Więcej gier</ActionButton>
                            </Col>
                        </Row>
                    )}
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <Header textFirst="Te kategorie" textSecond="mogą Ci się spodobać"/>
                        </Col>
                    </Row>
                    {categories && (
                        <Row className="mb-5 mb-lg-0">
                            {categories.map((category) => {
                                return (
                                    <Col className="px-2" xs="6" lg="3">
                                        <CategoryTile category={category}/>
                                    </Col>
                                );
                            })}
                        </Row>
                    )}
                </Container>
            </PageLayout>
        </>
    );
}