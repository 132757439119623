import React from "react";
import Header from "../../Text/Header";
import {Col, Container, Row} from "react-bootstrap";
import "./TextBlock.scss";

type Props = {
    title: string,
    content: string,
    image: string,
    colored?: boolean
}

export const TextBlock = (props: Props) => {
    const title = props.title;
    const first = title.substring(0, title.lastIndexOf(" ") + 1);
    const second = title.substring(title.lastIndexOf(" ") + 1, title.length);
    const version = props.colored ? "color" : "white";

    return (
        <Container className={`footer-text-block ${version}`}>
            <Row>
                <Col>
                    <Header textFirst={first} textSecond={second} version={version}/>
                </Col>
            </Row>
            <Row>
                <Col lg={6} className="footer-text-block-content">
                    <p dangerouslySetInnerHTML={{ __html: props.content}}></p>
                </Col>
                <Col lg={6} className="footer-text-block-img">
                    <img src={props.image}/>
                </Col>
            </Row>
        </Container>
    );
}
