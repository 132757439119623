import React from 'react';
import {Helmet} from "react-helmet";

type Props = {
    title?: string,
    description?: string
}

export const MetaTags = (props: Props) => {
    return (
        <>
            <Helmet>
                {props.title && (<title>{props.title}</title>)}
                {props.description && (<meta name="description" content={props.description}/>)}
            </Helmet>
        </>
    );
}