import React from 'react';
import {Container, Row} from "react-bootstrap";
import {TopRow} from "./Header/TopRow";
import {TopNavbar} from "./Header/TopNavbar";
import defaultBackground from "../../assets/header-bg.png";
import defaultBackground2 from "../../assets/header-bg2.png";
import {PageFooter} from "./Footer/PageFooter";

type Props = {
    children: React.ReactNode;
    background?: string;
};

export const PageLayout: React.FC<Props> = (props: Props) => {
    let background = props.background ?? defaultBackground;

    return (<>
            <Container fluid
                       style={{
                           backgroundImage: `url(${background})`,
                           backgroundRepeat: "no-repeat"
                       }}
                       className={"page-layout--container"}
            >
                <TopRow/>
                <TopNavbar/>
                <Row
                    style={{
                        backgroundImage: `url(${defaultBackground2})`,
                        backgroundSize: 'cover',
                        height: 70
                    }}>
                </Row>
            </Container>
            <Container fluid>
                {props.children}
            </Container>
            <PageFooter/>
        </>
    );
};