import React from 'react';
import {GameInterface} from "../../../interfaces";
import "./GameTile.scss";
import {Link} from "react-router-dom";

type Props = {
    game: GameInterface
}

export const GameTile = (props: Props) => {
    const game = props.game;

    let imgUrl = game.image;

    if (!game.image && game.assets) {
        let assets = game.assets.filter(a => a.url.includes('512x512'))
        if (assets.length) {
            imgUrl = assets[0].url
        }
    }


    return (
        <div className="game-tile">
            <Link to={`/gra/${game.slug}`}>
                <div className="game-tile-content">
                    <span className="game-tile-category"
                          style={{backgroundColor: game.categories[0].color}}>{game.categories[0].name}</span>
                    <img src={imgUrl}/>
                </div>
            </Link>
            <div className="game-tile-badge">
                <span className="game-tile-name">{game.name}</span>
            </div>
        </div>
    )
}