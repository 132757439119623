import React, {useState} from 'react';
import {GameInterface} from "../../../interfaces";
import "./GameIFrame.scss";
import {Button} from "react-bootstrap";
import {isMobile} from 'react-device-detect';

type Props = {
    game: GameInterface
}

export const GameIFrame = (props: Props) => {
    enum State {
        NotLoaded,
        Loading,
        Loaded,
    }

    const loadGame = () => {
        if (state === State.NotLoaded) {
            setState(State.Loading as State);
            setTimeout(() => {
                setState(State.Loaded as State);
            }, Math.floor(Math.random() * 1000) + 1000)
        }
    };

    const [state, setState] = useState<State>(State.NotLoaded as State);
    const game = props.game;
    const background = game.image ?? game.assets[0].url;

    function closeFullscreen() {
        setState(State.NotLoaded as State);
    }

    function openFullscreen() {
        setState(State.Loaded as State);
    }

    return (
        <div className="game-iframe">
            {state != State.Loaded && isMobile ? (
                <div className={`not-loaded-box text-center ${state == State.NotLoaded ? 'not-loaded' : 'loading'}`}
                     style={{
                         backgroundImage: `url(${background})`
                     }}>
                    <div className="icon">
                        {state == State.NotLoaded && (
                            <i className="bi bi-play-circle" onClick={() => openFullscreen()}/>
                        )}
                        {state == State.Loading && (
                            <i className="bi bi-arrow-clockwise rotating-icon"/>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    {state == State.Loaded && isMobile && (
                        <div className="close-button">
                            <i className="bi bi-x-lg" onClick={() => closeFullscreen()}/>
                        </div>
                    )}
                    <iframe {...(isMobile ? {allowFullScreen: true} : '')}
                            className={`${isMobile ? 'mobile-iframe' : ''}`} src={game.iframe_url}/>
                </>
            )}
        </div>
    )
}