import React, {useEffect, useState} from 'react';
import {GameInterface} from "../../../interfaces";
import axios, {AxiosResponse} from "axios";
import {GameTile} from "../GameTile/GameTile";
import './HeroGames.scss';

export const HeroGames = () => {
    const [games, setGames] = useState<GameInterface[]>([]);

    useEffect(() => {
        axios
            .get('/hero_block/')
            .then((resp: AxiosResponse) => {
                setGames(resp.data);
            })
    }, []);


    return (
        <div className="heroGames">
            {games.length && (
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="heroGames-tile60 game-large">
                                <GameTile game={games[0]}/>
                            </div>
                            <div className="heroGames-tile20 game-large">
                                <GameTile game={games[1]}/>
                            </div>
                            <div className="heroGames-tile20 game-large">
                                <GameTile game={games[2]}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="heroGames-tile20 game-small mobile-33">
                                <GameTile game={games[3]}/>
                            </div>
                            <div className="heroGames-tile20 game-small mobile-33">
                                <GameTile game={games[4]}/>
                            </div>
                            <div className="heroGames-tile20 game-small mobile-33">
                                <GameTile game={games[5]}/>
                            </div>
                            <div className="heroGames-tile40 game-small">
                                <GameTile game={games[6]}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}