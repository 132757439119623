import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Modal} from "react-bootstrap";
import {PageLayout} from "../Layout/PageLayout";
import {CategoryInterface, GameInterface, TagInterface} from "../../interfaces";
import {useNavigate, useParams} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import Header from "../Text/Header";
import {GameTile} from "../Blocks/GameTile/GameTile";
import {CategoryTile} from "../Blocks/CategoryTile/CategoryTile";
import {TextBlock} from "../Blocks/TextBlock/TextBlock";
import videoGamesImg from "../../assets/video_games.png";
import {ActionButton} from "../Button/Action/ActionButton";
import {MetaTags} from "../Seo/MetaTags/MetaTags";
import {ErrorGame} from "../Blocks/ErrorGame/ErrorGame";
import {GameIFrame} from "../Blocks/GameIFrame/GameIFrame";

type Props = {}

export const GamePage = (props: Props) => {
    const [game, setGame] = useState<GameInterface>();
    const [games, setGames] = useState<GameInterface[]>([]);
    const [tags, setTags] = useState<TagInterface[]>([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        if (params.slug) {
            axios
                .get(`/game/${params.slug}`)
                .then((resp: AxiosResponse) => {
                    setGame(resp.data);
                }).catch((error) => {
                navigate('/404');
            });
        }
    }, [params]);

    useEffect(() => {
        if (game) {
            axios
                .get('/game/', {
                    params: {
                        categories: game.categories[0].id,
                        limit: 8,
                        rand: true
                    }
                })
                .then((resp: AxiosResponse) => {
                    setGames(resp.data.results);
                })

            var related: TagInterface[] = [];
            game.tags.forEach((tag) => {
                tag.related.forEach((rTag) => {
                    if (related.findIndex(x => x.id == rTag.id) === -1) {
                        related.push(rTag);
                    }
                })
            })
            setTags(related);
        }
    }, [game])

    return (
        <>
            {game && (
                <>
                    <MetaTags title={game.meta_title} description={game.meta_description}/>
                    <PageLayout>
                        <Container>
                            <Row>
                                <Col className="d-none d-lg-block" xl="2">
                                    {games.length && (
                                        games.slice(0, 4).map((game) => {
                                            return (
                                                <Row key={game.id}>
                                                    <Col>
                                                        <GameTile game={game}/>
                                                    </Col>
                                                </Row>
                                            );
                                        }))
                                    }
                                </Col>
                                <Col xl="8">
                                    <Header headerLocation="gamePage" textSecond={game.name}/>
                                    {game.iframe_url ? (
                                        <div className="iframe-wrapper mb-3 mb-lg-5">
                                            <GameIFrame game={game} />
                                        </div>
                                    ) : (
                                        <div className="mb-3 mb-lg-5">
                                            <ErrorGame game={game}/>
                                        </div>
                                    )}
                                    <Row>
                                        <Col className="d-flex justify-content-between">
                                            <div className="game-attributes"></div>
                                            <ActionButton icon="controller" action={handleShow}>Jak grać?</ActionButton>
                                            <Modal show={show} onHide={handleClose}>
                                                <Modal.Header closeButton>
                                                    <span className="modal-heading">Jak grać?</span>
                                                </Modal.Header>
                                                <Modal.Body className="modal-content">{game.instructions}</Modal.Body>
                                            </Modal>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="d-none d-lg-block" xl="2">
                                    {games.length && (
                                        games.slice(4, 8).map((game) => {
                                            return (
                                                <Row key={game.id}>
                                                    <Col>
                                                        <GameTile game={game}/>
                                                    </Col>
                                                </Row>
                                            );
                                        }))
                                    }
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col>
                                    <Header textFirst="Te kategorie" textSecond="mogą Ci się spodobać"/>
                                </Col>
                            </Row>
                            {tags && (
                                <Row className="mb-5">
                                    {tags.map((tag) => {
                                        return (
                                            <Col xs="6" lg="3" className="px-2" key={tag.id}>
                                                <CategoryTile category={tag} tag/>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )}
                        </Container>
                        {(game.title || game.description) &&
                        <Container>
                            <TextBlock title={game.title ?? ''} content={game.description ?? ''} image={videoGamesImg}
                                       colored/>
                        </Container>
                        }
                    </PageLayout>
                </>
            )}
        </>
    );
}