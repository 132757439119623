import React from 'react';
import { Container, Row, Col, Form, FormControl, Nav, Navbar } from "react-bootstrap";
import logo from "../../../assets/logo.png";
import { ButtonImage } from "../../Button/ButtonImage/ButtonImage";
import heartImage from "../../../assets/heart.svg";
import playAgainImage from "../../../assets/play-again.svg";
import { Link } from "react-router-dom";
import { MobileMenu } from './MobileMenu';
import { SearchBar } from '../../Blocks/SearchBar/SearchBar';

export const TopRow = () => (

    <Container className="py-4">
        <Row>
            <Col xs="6" lg="3">
                <Link to="/">
                    <Navbar.Brand>
                        <img
                            src={logo}
                            className="d-inline-block align-top"
                            alt="mojegry.pl logo"
                        />
                    </Navbar.Brand>
                </Link>
            </Col>
            <Col lg="6">
                <SearchBar />
            </Col>
            <Col xs="6" lg="3" className="top-row--icons_wrap">
                {/*<Nav className="top-row--icons d-none d-lg-flex">*/}
                {/*    <Nav.Link href="#home">*/}
                {/*        <ButtonImage*/}
                {/*            image={heartImage}*/}
                {/*            backgroundColorFrom="#FF4949"*/}
                {/*            backgroundColorTo="#CF3333"*/}
                {/*        />*/}
                {/*    </Nav.Link>*/}
                {/*    <Nav.Link href="#home">*/}
                {/*        <ButtonImage*/}
                {/*            image={playAgainImage}*/}
                {/*            backgroundColorFrom="#FC9447"*/}
                {/*            backgroundColorTo="#F77830"*/}
                {/*        />*/}
                {/*    </Nav.Link>*/}
                {/*</Nav>*/}
                <MobileMenu icon="grid" />
            </Col>
        </Row>
    </Container>

);
