import React from 'react';
import {Container} from "react-bootstrap";
import {TopRow} from "./Header/TopRow";
import {TopNavbar} from "./Header/TopNavbar";
import defaultBackground from "../../assets/home_bg.png";
import {MainFooter} from "./Footer/MainFooter";

type Props = {
    children: React.ReactNode;
    background?: string;
};

export const MainLayout: React.FC<Props> = (props: Props) => {
    let background = props.background ?? defaultBackground;

    return (<>
            <Container fluid
                       style={{
                           backgroundImage: `url(${background})`,
                           backgroundRepeat: "no-repeat"
                       }}
                       className={"main-layout--container"}
            >
                <TopRow/>
                <TopNavbar/>
                {props.children}
            </Container>
            <MainFooter/>
        </>
    );
};