import axios, {AxiosResponse} from "axios";
import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {StaticPageInterface} from "../../interfaces";
import {PageLayout} from "../Layout/PageLayout";
import Header from "../Text/Header";
import {useNavigate} from "react-router-dom";
import {MetaTags} from "../Seo/MetaTags/MetaTags";

type Props = {}
export const StaticPage = (props: Props) => {

    const [staticData, setStaticData] = useState<StaticPageInterface>();

    let params = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        axios
            .get(`/static_page/${params.slug}`)
            .then((resp: AxiosResponse) => {
                setStaticData(resp.data);
            })
    }, []);

    return (
        <>
            <MetaTags title={staticData?.meta_title} description={staticData?.meta_description}/>
            <PageLayout>
                <Container>
                    <Row>
                        <Col>
                            <Header textFirst={staticData?.name ?? ''}/>
                            <div dangerouslySetInnerHTML={{__html: staticData?.content ?? ''}}/>
                        </Col>
                    </Row>

                </Container>

            </PageLayout>
        </>
    );
}
