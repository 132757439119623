import React from 'react';
import {Button} from "react-bootstrap";
import './ButtonRounded.scss';

type Props = {
    title: string;
    icon: string;
    background: string;
};

export const ButtonRounded = (props: Props) => (
    <Button variant="light" className="button-rounded" style={{
        backgroundColor: props.background
    }}>
        <img src={props.icon}/>
        {props.title}
    </Button>
);