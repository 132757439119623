import React from "react";
import {FooterNavbar} from "./FooterNavbar/FooterNavbar";
import {FooterSignature} from "./FooterSignature/FooterSignature";
import {Container} from "react-bootstrap";
import footerBackground from "../../../assets/footer-bg-main.png";
import {FooterTopBackground} from "./FooterTopBackground/FooterTopBackground";
import {TextBlock} from "../../Blocks/TextBlock/TextBlock";
import videoGamesImg from "../../../assets/video_games.png";
import { MobileFooterMenu } from "./MobileFooterMenu/MobileFooterMenu";

export const MainFooter = () => {
    const content = "Mojegry.pl to wirtualny plac zabaw z darmowymi grami online dla dzieci w każdym wieku. Niezależnie od tego ile masz lat, czeka Cię tu wiele wspaniałych wyzwań i przygód. Nasze gry podzieliliśmy na ponad 170 kategorii, a znajdziesz w nich dosłownie wszystko! Mamy największy zbiór gier edukacyjnych, które w formie zabawy nauczą Cię nowych, ciekawych rzeczy oraz pomogą utrwalić te które już znasz. Dla chłopaków przygotowaliśmy cały dział sportowy oraz gry o prowadzeniu niemal wszystkich istniejących pojazdów. Dziewczyny mogą wybrać się na zakupy, zostać światowej sławy stylistkami lub udekorować pokój swoich marzeń. Nasi najmłodsi gracze znajdą tutaj mnóstwo ciekawych puzzli i kolorowanek oraz spotkają ulubionych bohaterów kreskówek. Lubisz popularne gry takie jak kulki, trzy w jednej linii lub Mahjong? Mamy ich najlepsze edycje! A dla młodych graczy, którzy cenią wspólną zabawę z przyjaciółmi, przygotowaliśmy wciągające gry dla dwóch, a nawet trzech i czterech osób! <br/><br/> Nie musisz instalować żadnych dodatkowych wtyczek. Nasze gry uruchomisz bez problemu w każdej przeglądarce internetowej na komputerze, smartfonie i tablecie. Kiedy masz wolną chwilę, pamiętaj, żeby odwiedzić Gryfka po kolejną dawkę najlepszych darmowych gier online!";

    return (
        <>
            <FooterTopBackground class="footer-img" background={footerBackground} />
            <Container fluid className="main-footer" style={{backgroundColor: "#4090FF"}}>
                <TextBlock title={"Gry dla dzieci online"} content={content} image={videoGamesImg}/>
                <FooterNavbar/>
                <FooterSignature/>
                <MobileFooterMenu/>
            </Container>
        </>
    );
}