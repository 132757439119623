import React, {useEffect, useState} from 'react';
import {MainLayout} from "../Layout/MainLayout";
import axios, {AxiosResponse} from "axios";
import {GamesBlock} from "../Blocks/GamesBlock/GamesBlock";
import {GamesBlockInterface} from "../../interfaces";
import {Col, Container, Row} from "react-bootstrap";
import {HeroGames} from '../Blocks/HeroGames/HeroGames';
function MainPage() {
    const [gamesBlocks, setGamesBlocks] = useState<GamesBlockInterface[]>([]);

    useEffect(() => {
        axios
            .get('/games_block/', {
                params: {
                    place: "main"
                }
            })
            .then((resp: AxiosResponse) => {
                setGamesBlocks(resp.data)
            })
    }, []);

    return (
        <MainLayout>
            <Container>
                <Row>
                    <Col>
                    <HeroGames/>
                        {gamesBlocks.map((gameBlock) => {
                            return <GamesBlock gamesBlockData={gameBlock}/>
                        })}
                    </Col>
                </Row>
            </Container>
        </MainLayout>
    );
}

export default MainPage;